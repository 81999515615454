<template>
    <div class="wrapper">
        <div class="body">
            <el-form :model="forgetPasswordForm" :rules="rules" ref="forgetPasswordForm">
                <el-form-item>
                    <span style="font-size: 18px; font-weight: bold;">{{$t('login.resetPasswords')}}</span>
                </el-form-item>
                <el-form-item prop="newPwd">
                    <el-input
                            v-model="forgetPasswordForm.newPwd"
                            :placeholder="$t('login.set8DigitsSecretLetterCombination')"
                            :show-password="true"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item prop="confirmPwd">
                    <el-input
                            v-model="forgetPasswordForm.confirmPwd"
                            :placeholder="$t('login.confirmSecretCode')"
                            :show-password="true"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button class="but" @click="resetPasswords" round>{{$t('login.intoGreatChaoZan')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {resetPasswords} from "../../../request/api/merchant";

    export default {
        name: "ForgetPassword",
        data(){
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(this.$t('login.enterPin')));
                } else {
                    if (this.forgetPasswordForm.confirmPwd !== '') {
                        this.$refs.forgetPasswordForm.validateField('confirmPwd');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(this.$t('login.pleaseEnterPasswordAgain')));
                } else if (value !== this.forgetPasswordForm.newPwd) {
                    callback(new Error(this.$t('login.inconsistentPasswordsEnteredTwice')));
                } else {
                    callback();
                }
            };
            return {
                forgetPasswordForm: {
                    newPwd: '',
                    confirmPwd: ''
                },
                rules: {
                    newPwd: [
                        { required: true, message: this.$t('login.enterPin'), trigger: 'blur' },
                        { min: 8, max: 16, message: this.$t('login.lengthBetween8And16Characters'), trigger: 'blur' },
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    confirmPwd:[
                        { required: true, message: this.$t('login.pleaseConfirmPassword'), trigger: 'blur' },
                        { min: 8, max: 16, message: this.$t('login.lengthBetween8And16Characters'), trigger: 'blur' },
                        { validator: validatePass2, trigger: 'blur', required: true }
                    ],
                }
            }
        },
        methods:{
            resetPasswords() {
                let _this = this;
                this.$refs["forgetPasswordForm"].validate((val) =>{
                    if (val){
                        resetPasswords({
                            'password': _this.forgetPasswordForm.confirmPwd,
                        }).then((result) =>{
                            if (result.code === 200){
                                this.$router.replace('/enterpriseInformation');
                            }else {
                                _this.$notify({
                                    title: _this.$t('login.hint'),
                                    message: result.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }else {
                        console.error("error submit");
                        return false;
                    }
                });

            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index.less";

    @input-width: 400px;
    .wrapper {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .body {
            width: @input-width;
            height: 500px;

            .but {
                width: @input-width;
                text-align: center;
                cursor: pointer;
                color: white;
                background-color: @color-primary;
            }
        }
    }
</style>
